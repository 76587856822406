import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { Box, Typography } from '@material-ui/core';
import { getGenericSearchWithSearchId } from '../../../../api/pages/ResultsPage';
import RESULT_VIEW_TYPES from '../constants';
import mergeMatchSubmission, { checkForMatchSubmission } from '../../utils/getMatchSubmissionData';
import getMatchSubmissions from '../../hooks/getMatchSubmissions';
import TimelineView from './TimlineView';
import styles from './styles';
import FullScreenProcessing from '../../../SpecialityDesignation/components/FullScreenProcessing';
import groupIndicationTermByYear from './utils';

const IndicationApprovalTimelineReport = ({ searchId, source, id, onUpdatePlacement }: any) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [groupByYearData, setGroupByYearData] = useState<any>({});
  const groupByYear = (data: any) => {
    const groupData = groupIndicationTermByYear(data, source);
    setGroupByYearData(groupData);
  };

  const getSubmissionData = async () => {
    setLoading(true);
    const res = await getGenericSearchWithSearchId({
      searchId,
      view_type: RESULT_VIEW_TYPES.APPLICATION
    });
    if (res?.status === 200 || res?.status === 204) {
      const result = res?.data?.body?.result || {};
      const applicationResults = { ...result };
      if (searchId && !checkForMatchSubmission(applicationResults)) {
        const submissionData = await getMatchSubmissions(searchId);

        const newApplData = mergeMatchSubmission(applicationResults, submissionData);
        groupByYear(newApplData);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    getSubmissionData();
  }, []);

  if (isLoading) {
    return (
      <FullScreenProcessing loading={isLoading} open={isLoading} message='Generating image...' />
    );
  }
  return (
    <Box height='100%' width='100%' position='relative'>
      {Object.keys(groupByYearData?.result || {}).length !== 0 ? (
        <Grid
          container
          flexWrap='nowrap'
          id={`timeline-${id}`}
          sx={{
            overflow: 'auto',
            height: '100%',
            width: '100%'
          }}>
          <TimelineView
            dataByYear={groupByYearData?.result}
            drugs={groupByYearData?.appArray}
            applicationNumbers={groupByYearData?.applList}
            searchTermList={groupByYearData?.searchTerms}
            onUpdatePlacement={onUpdatePlacement}
            isReport
          />
        </Grid>
      ) : (
        <Box sx={styles.message}>
          <Typography variant='h6' color='inherit'>
            No Graphs Available
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default React.memo(IndicationApprovalTimelineReport);
